import i18n from 'helpers/i18n';
import messages from './messages';

export const PER_PAGE = 20;
export const learningResourceRoles = [
  { id: 'lr_admin', name: 'Admin' },
  { id: 'lr_coach', name: 'Coach' },
  { id: 'lr_educator', name: 'Educator' }
];

export const statusList = [
  { id: 'accepted', name: 'Accepted' },
  { id: 'invited', name: 'Invited' },
  { id: 'no_invitation', name: 'Not Invited' }
];

export function getStatusText(status: string) {
  switch (status) {
    case 'accepted':
      return i18n.ft(messages.acceptedStatus);
    case 'invited':
      return i18n.ft(messages.invitedStatus);
    default:
      return i18n.ft(messages.notInvitedStatus);
  }
}
