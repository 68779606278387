const PATH = 'fe.components.organization.learning_resources';

const messages = {
  usersCount: {
    key: `${PATH}.users_count`,
    defaultValue: 'Showing {{initial}}-{{end}} of {{total}}'
  },
  totalPages: {
    key: `${PATH}.total_pages`,
    defaultValue: 'Total: {{count}} pages'
  },
  deselectAll: {
    key: `${PATH}.deselect_all`,
    defaultValue: 'Deselect All'
  },
  assignCoach: {
    key: `${PATH}.assign_coach`,
    defaultValue: 'Assign to Coach'
  },
  resendInvite: {
    key: `${PATH}.resend_invite`,
    defaultValue: 'Resend Invite ({{count}})'
  },
  unsubscribe: {
    key: `${PATH}.unsubscribe`,
    defaultValue: 'Unsubscribe Selected ({{count}})'
  }
};

export default messages;
