import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

type CheckSubscriptionResponse = {
  id: number;
  role: string;
  name: string;
  email: string;
  invite_status: 'invited' | 'accepted' | 'no_invitation';
  invited_on: string;
};

export function getSubscriptionStatus(params: {
  email: string;
  subscription_id: number;
}) {
  return axios.post<CheckSubscriptionResponse>(
    `/api/v2/memberships/check_subscription`,
    {
      email: params.email,
      subscription_id: params.subscription_id
    }
  );
}

export function getUnsubscribedUsers(subscriptionId: number, params: any = {}) {
  return axios.get(
    `/api/v2/memberships/unsubscribed?subscription_id=${subscriptionId}`,
    {
      params
    }
  );
}

export function useGetUnsubscribedUsers(
  subscriptionId: number,
  params: any = {}
) {
  return useQuery({
    queryKey: ['subscriptions', subscriptionId, 'unsubscribed', params],
    queryFn: () =>
      getUnsubscribedUsers(subscriptionId, params).then(
        response => response.data
      )
  });
}
