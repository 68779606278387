const PATH = 'fe.components.organization.coach_groups';

const messages = {
  nameAndEmail: {
    key: `${PATH}.name_and_email`,
    defaultValue: 'Name & Email'
  },
  educators: {
    key: `${PATH}.educators`,
    defaultValue: 'Educators'
  },
  view: {
    key: `${PATH}.view`,
    defaultValue: 'View ({{count}})'
  },
  success: {
    key: `${PATH}.success`,
    defaultValue:
      '{{educators}} educator(s) have been assigned to the coach group'
  },
  error: {
    key: `${PATH}.error`,
    defaultValue: 'Error assigning users to coach. Please try again later.'
  },
  learningResources: {
    key: `${PATH}.learning_resources`,
    defaultValue: 'Learning Resources'
  },
  unsubscribedUsers: {
    key: `${PATH}.unsubscribed_users`,
    defaultValue: 'View Unsubscribed Users'
  },
  searchPlaceholder: {
    key: `${PATH}.search_placeholder`,
    defaultValue: 'Search name or email'
  },
  invite: {
    key: `${PATH}.invite`,
    defaultValue: 'Invite Users'
  },
  unsubscribe: {
    key: `${PATH}.unsubscribe`,
    defaultValue: 'Unsubscribe Selected ({{count}})'
  },
  deselectAll: {
    key: `${PATH}.deselect_all`,
    defaultValue: 'Deselect All'
  },
  assignCoach: {
    key: `${PATH}.assign_coach`,
    defaultValue: 'Assign to Coach'
  },
  resendInvite: {
    key: `${PATH}.resend_invite`,
    defaultValue: 'Resend Invite ({{count}})'
  },
  usersCount: {
    key: `${PATH}.users_count`,
    defaultValue: 'Showing {{initial}}-{{end}} of {{total}}'
  },
  totalPages: {
    key: `${PATH}.total_pages`,
    defaultValue: 'Total: {{count}} pages'
  }
};

export default messages;
