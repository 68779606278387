import i18n from 'helpers/i18n';
import messages from './messages';
import PageLoader from 'components/PageLoader';
import { useParams } from 'react-router-dom';
import { roleToText } from 'helpers/role';
import { Table } from 'components/Organization/Table';
import { Pagination } from 'components/Organization/Pagination';
import { useGetUnsubscribedUsers } from 'actions/memberships';
import { useRef, useState } from 'react';
import RolePopup from 'components/Organization/RolePopup';
import { composeUser, formatDate } from '../Members/utils';
import { Checkbox } from '@headlessui/react';
import SelectUsersBanner from 'components/Organization/SelectUsersBanner';
import { PER_PAGE, learningResourceRoles } from '../LearningResources/utils';
import { UnsubscribedUser } from 'types/LearningResources/types';

interface UnsubscribedUsersProps {
  searchQuery: string;
}

function UnsubscribedUsers({ searchQuery }: UnsubscribedUsersProps) {
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortDir, setSortDir] = useState<'asc' | 'desc' | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const filterRoleButtonRef = useRef<HTMLButtonElement>(null);
  const { id: subscriptionId } = useParams();

  const handleRoleFilterButtonClick = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleRolePopupClose = () => {
    setIsPopupOpen(false);
    filterRoleButtonRef.current?.focus();
  };

  const handleRolesChange = (newRoles: string[]) => {
    setSelectedRoles(newRoles);
  };

  const handleRowSelect = (userId: number) => {
    setSelectedRows(prevSelectedRows =>
      prevSelectedRows.includes(userId)
        ? prevSelectedRows.filter(id => id !== userId)
        : [...prevSelectedRows, userId]
    );
  };

  const handleHeaderSelect = () => {
    if (!data || !data.unsubscribed) return;

    if (selectedRows.length <= 0) {
      const allUserIdsOnPage = data.unsubscribed.map(
        (membership: any) => membership.id
      );
      setSelectedRows(allUserIdsOnPage);
    } else {
      setSelectedRows([]);
    }
  };

  const sortMapping: { [key: string]: string } = {
    name: 'name',
    email: 'email',
    role: 'role',
    invited_on: 'created_at',
    unsubscribed_on: 'deleted_at'
  };

  const baseParams = {
    query: searchQuery,
    sort_by: sortBy && sortBy in sortMapping ? sortMapping[sortBy] : sortBy,
    sort_dir: sortDir,
    role: selectedRoles
  };

  const tableParams = {
    ...baseParams,
    per_page: PER_PAGE,
    page
  };

  const filteredTableParams = Object.fromEntries(
    Object.entries(tableParams).filter(([_, value]) => value != null)
  );

  const { data, isLoading } = useGetUnsubscribedUsers(
    parseInt(subscriptionId ?? '0'),
    filteredTableParams
  );

  const handleSort = (header: string) => {
    if (header === sortBy && sortDir === 'asc') {
      setSortDir('desc');
    } else if (header === sortBy && sortDir === 'desc') {
      setSortBy(null);
      setSortDir(null);
    } else {
      setSortBy(header);
      setSortDir('asc');
    }
  };

  const getLastPage = () => {
    if (page === 1) return PER_PAGE;

    const totalPages = data?.pagination.total_pages;
    if (page === totalPages) {
      return (data?.unsubscribed.length || 0) + PER_PAGE * (page - 1);
    }

    return page * PER_PAGE;
  };

  const tableHeaders = [
    {
      content: (
        <Checkbox
          checked={selectedRows.length > 0}
          onChange={handleHeaderSelect}
          className="text-xl text-action"
        >
          {({ checked }) =>
            checked ? (
              <i className="fa-solid fa-square-minus" />
            ) : (
              <i className="fa-regular fa-square" />
            )
          }
        </Checkbox>
      ),
      accessor: 'checkbox'
    },
    {
      content: i18n.ft(messages.nameAndEmail),
      accessor: 'name',
      sortable: true
    },
    {
      content: i18n.ft(messages.role),
      accessor: 'role',
      filter: (
        <div className="flex gap-2">
          <button
            ref={filterRoleButtonRef}
            onClick={handleRoleFilterButtonClick}
            className={`font-bold ${
              isPopupOpen ? 'text-action' : 'text-black'
            }`}
            aria-haspopup="true"
            aria-expanded={isPopupOpen}
            aria-label={i18n.ft(messages.filterRoles)}
          >
            <i className="fa-solid fa-bars-filter fa-sm"></i>
          </button>
          {isPopupOpen && (
            <RolePopup
              accountId={-1}
              isOpen={isPopupOpen}
              onClose={handleRolePopupClose}
              onChange={handleRolesChange}
              selectedValues={selectedRoles}
              roleList={learningResourceRoles}
            />
          )}
          {selectedRoles.length > 0 && (
            <div>
              <button
                onClick={() => setSelectedRoles([])}
                className="text-action cursor-pointer"
                aria-label={i18n.ft(messages.clearRoles)}
              >
                <i className="fa-regular fa-circle-xmark" />
              </button>
            </div>
          )}
        </div>
      )
    },
    {
      content: i18n.ft(messages.invitedOn),
      accessor: 'invited_on',
      sortable: true
    },
    {
      content: i18n.ft(messages.unsubscribedOn),
      accessor: 'unsubscribed_on',
      sortable: true
    }
  ];

  const tableData = data?.unsubscribed.map((membership: UnsubscribedUser) => {
    return {
      selected: selectedRows.includes(membership.id),
      checkbox: (
        <Checkbox
          checked={selectedRows.includes(membership.id)}
          onChange={() => handleRowSelect(membership.id)}
          className="text-xl text-action"
        >
          {({ checked }) =>
            checked ? (
              <i className="fa-solid fa-square-check" />
            ) : (
              <i className="fa-regular fa-square" />
            )
          }
        </Checkbox>
      ),
      name: composeUser(membership.id, membership.name, membership.email),
      email: membership.email,
      role: roleToText(membership.role),
      invited_on: formatDate(membership.invited_at ?? undefined),
      unsubscribed_on: formatDate(membership.unsubscribed_at ?? undefined)
    };
  });

  const membersCount = data?.pagination.total_pages;

  function resubscribeUsers() {
    // TODO: to be implemented
  }

  return (
    <div className="my-8 relative isolate">
      <div className=" pb-12 bg-white rounded-2xl">
        {isLoading ? (
          <PageLoader />
        ) : (
          <>
            {tableData && (
              <>
                <div className="text-[#3C3F42] mt-6 mb-1.5 text-sm">
                  {i18n.ft(messages.usersCount, {
                    initial: page === 1 ? 1 : (page - 1) * PER_PAGE + 1,
                    end: getLastPage(),
                    total: membersCount
                  })}
                </div>

                <Table
                  headers={tableHeaders}
                  data={tableData}
                  sortBy={sortBy}
                  sortDir={sortDir}
                  onSort={handleSort}
                  actions={
                    selectedRows.length > 0 ? (
                      <SelectUsersBanner
                        setSelectedRows={setSelectedRows}
                        resubscribeText={i18n.ft(messages.resubscribe, {
                          count: selectedRows.length
                        })}
                        deselectAllText={i18n.ft(messages.deselectAll)}
                        resubscribeMethod={() => resubscribeUsers()}
                      />
                    ) : null
                  }
                />

                <div className="mt-6 flex justify-center">
                  <Pagination
                    page={page}
                    onPageChange={setPage}
                    total={data?.pagination.total_pages ?? 0}
                    label={i18n.ft(messages.totalPages, {
                      count: membersCount
                    })}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default UnsubscribedUsers;
