import debounce from 'debounce';
import i18n from 'helpers/i18n';
import messages from './messages';
import PageLoader from 'components/PageLoader';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { roleToText } from 'helpers/role';
import { PrimaryButton } from 'components/Organization/PrimaryButton';
import { useGetLearningResourceUsers } from 'actions/learningResources';
import RouteHelpers from 'helpers/routes';
import { useCallback, useEffect, useRef, useState } from 'react';
import RolePopup from 'components/Organization/RolePopup';
import { composeUser, formatDate } from '../Members/utils';
import { Checkbox } from '@headlessui/react';
import StatusBadge from 'components/Organization/StatusBadge';
import AssignCoachModal from 'components/Organization/AssignCoachModal';
import {
  getStatusText,
  PER_PAGE,
  learningResourceRoles,
  statusList
} from './utils';
import { MembershipTrackers } from 'types/LearningResources/types';
import { createCoachings } from 'actions/coachings';
import { toastError, toastSuccess } from 'components/Organization/Toast';
import { UserStatusSelect } from '../Members/UserStatusSelect';
import { BackgroundSVG } from '../Members/BackgroundSVG';
import CoachGroups from 'components/Organization/CoachGroups/CoachGroups';
import ActiveUsersView from 'components/Organization/LearningResources/ActiveUsersView';
import UnsubscribedUsers from '../UnsubscribedUsers/UnsubscribedUsers';

type LearningResourceView = 'active' | 'unsubscribe' | 'coachGroups';

function LearningResources() {
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortDir, setSortDir] = useState<'asc' | 'desc' | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isInviteStatusPopupOpen, setIsInviteStatusPopupOpen] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [educatorsOnly, setEducatorsOnly] = useState<number[]>([]);
  const [roles, setRoles] = useState({
    coaches: 0,
    admins: 0,
    educators: 0
  });
  const [isAssignCoachModalOpen, setIsAssignCoachModalOpen] = useState(false);
  const filterRoleButtonRef = useRef<HTMLButtonElement>(null);
  const filterInviteStatusButtonRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const { id: subscriptionId } = useParams<{ id: string }>();
  const [view, setView] = useState<LearningResourceView>('active');

  const handleInviteStatusFilterButtonClick = () => {
    setIsInviteStatusPopupOpen(!isInviteStatusPopupOpen);
  };

  const handleRoleFilterButtonClick = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleRolePopupClose = () => {
    setIsPopupOpen(false);
    filterRoleButtonRef.current?.focus();
  };

  const handleInviteStatusPopupClose = () => {
    setIsInviteStatusPopupOpen(false);
    filterInviteStatusButtonRef.current?.focus();
  };

  const handleRolesChange = (newRoles: string[]) => {
    setSelectedRoles(newRoles);
  };

  const handleStatusChange = (newRoles: string[]) => {
    setSelectedStatuses(newRoles);
  };

  const handleRowSelect = (userId: number) => {
    setSelectedRows(prevSelectedRows =>
      prevSelectedRows.includes(userId)
        ? prevSelectedRows.filter(id => id !== userId)
        : [...prevSelectedRows, userId]
    );
  };

  const handleHeaderSelect = () => {
    if (!data || !data.membership_trackers) return;

    if (selectedRows.length <= 0) {
      const allUserIdsOnPage = data.membership_trackers.map(
        (membership: any) => membership.id
      );
      setSelectedRows(allUserIdsOnPage);
    } else {
      setSelectedRows([]);
    }
  };

  const sortMapping: { [key: string]: string } = {
    name: 'name',
    email: 'email',
    role: 'role',
    invite_status: 'invite_status',
    invited_on: 'invited_on'
  };

  const baseParams = {
    query: searchQuery,
    sort_by: sortBy && sortBy in sortMapping ? sortMapping[sortBy] : sortBy,
    sort_dir: sortDir,
    role: selectedRoles,
    status: selectedStatuses
  };

  const tableParams = {
    ...baseParams,
    per_page: PER_PAGE,
    page
  };

  const filteredTableParams = Object.fromEntries(
    Object.entries(tableParams).filter(([_, value]) => value != null)
  );

  const { data, isLoading } = useGetLearningResourceUsers(
    parseInt(subscriptionId ?? '0'),
    filteredTableParams
  );

  const filterByRole = useCallback(
    (role: string) => {
      return data.membership_trackers.filter((membership: any) => {
        return (
          selectedRows.includes(membership.user_id) && membership.role === role
        );
      });
    },
    [data, selectedRows]
  );

  useEffect(() => {
    if (!data || !data.membership_trackers) return;

    const coaches = filterByRole('lr_coach');
    const admins = filterByRole('lr_admin');
    const educators = filterByRole('lr_educator');
    const educatorsOnly = educators.map(
      (membership: any) => membership.user_id
    );

    setEducatorsOnly(educatorsOnly);

    setRoles({
      coaches: coaches.length,
      admins: admins.length,
      educators: educators.length
    });
  }, [data, filterByRole, selectedRows]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setPage(1);
  };

  const handleSort = (header: string) => {
    if (header === sortBy && sortDir === 'asc') {
      setSortDir('desc');
    } else if (header === sortBy && sortDir === 'desc') {
      setSortBy(null);
      setSortDir(null);
    } else {
      setSortBy(header);
      setSortDir('asc');
    }
  };

  const getLastPage = () => {
    if (page === 1) return PER_PAGE;

    const totalPages = data?.pagination.total_pages;
    if (page === totalPages) {
      return (data?.membership_trackers.length || 0) + PER_PAGE * (page - 1);
    }

    return page * PER_PAGE;
  };

  const tableHeaders = [
    {
      content: (
        <Checkbox
          checked={selectedRows.length > 0}
          onChange={handleHeaderSelect}
          className="text-xl text-action"
        >
          {({ checked }) =>
            checked ? (
              <i className="fa-solid fa-square-minus" />
            ) : (
              <i className="fa-regular fa-square" />
            )
          }
        </Checkbox>
      ),
      accessor: 'checkbox'
    },
    {
      content: i18n.ft(messages.nameAndEmail),
      accessor: 'name',
      sortable: true
    },
    {
      content: i18n.ft(messages.role),
      accessor: 'role',
      filter: (
        <div className="flex gap-2">
          <button
            ref={filterRoleButtonRef}
            onClick={handleRoleFilterButtonClick}
            className={`font-bold ${
              isPopupOpen ? 'text-action' : 'text-black'
            }`}
            aria-haspopup="true"
            aria-expanded={isPopupOpen}
            aria-label={i18n.ft(messages.filterRoles)}
          >
            <i className="fa-solid fa-bars-filter fa-sm"></i>
          </button>
          {isPopupOpen && (
            <RolePopup
              accountId={-1}
              isOpen={isPopupOpen}
              onClose={handleRolePopupClose}
              onChange={handleRolesChange}
              selectedValues={selectedRoles}
              roleList={learningResourceRoles}
            />
          )}
          {selectedRoles.length > 0 && (
            <div>
              <button
                onClick={() => setSelectedRoles([])}
                className="text-action cursor-pointer"
                aria-label={i18n.ft(messages.clearRoles)}
              >
                <i className="fa-regular fa-circle-xmark" />
              </button>
            </div>
          )}
        </div>
      )
    },
    {
      content: i18n.ft(messages.inviteStatus),
      accessor: 'invite_status',
      className: 'max-w-md',
      filter: (
        <div className="flex gap-2">
          <button
            ref={filterInviteStatusButtonRef}
            onClick={handleInviteStatusFilterButtonClick}
            className={`font-bold ${
              isInviteStatusPopupOpen ? 'text-action' : 'text-black'
            }`}
            aria-haspopup="true"
            aria-expanded={isInviteStatusPopupOpen}
            aria-label={i18n.ft(messages.filterStatus)}
          >
            <i className="fa-solid fa-bars-filter fa-sm"></i>
          </button>
          {isInviteStatusPopupOpen && (
            <RolePopup
              accountId={-1}
              isOpen={isInviteStatusPopupOpen}
              onClose={handleInviteStatusPopupClose}
              onChange={handleStatusChange}
              selectedValues={selectedStatuses}
              roleList={statusList}
            />
          )}
          {selectedStatuses.length > 0 && (
            <div>
              <button
                onClick={() => setSelectedStatuses([])}
                className="text-action cursor-pointer"
                aria-label={i18n.ft(messages.clearStatus)}
              >
                <i className="fa-regular fa-circle-xmark" />
              </button>
            </div>
          )}
        </div>
      )
    },
    {
      content: i18n.ft(messages.invited),
      accessor: 'invited_on',
      sortable: true
    },
    {
      content: i18n.ft(messages.coach),
      accessor: 'coach',
      sortable: false
    }
  ];

  const tableData = data?.membership_trackers.map(
    (membership: MembershipTrackers) => {
      return {
        selected: selectedRows.includes(membership.user_id),
        checkbox: (
          <Checkbox
            checked={selectedRows.includes(membership.user_id)}
            onChange={() => handleRowSelect(membership.user_id)}
            className="text-xl text-action"
          >
            {({ checked }) =>
              checked ? (
                <i className="fa-solid fa-square-check" />
              ) : (
                <i className="fa-regular fa-square" />
              )
            }
          </Checkbox>
        ),
        name: composeUser(
          membership.user_id,
          membership.name,
          membership.email
        ),
        email: membership.email,
        role: roleToText(membership.role),
        last_login: '',
        invited_on: formatDate(membership.invited_on ?? undefined),
        invite_status: (
          <StatusBadge
            iconType={membership.invite_status}
            text={getStatusText(membership.invite_status)}
          />
        ),
        coach:
          membership.role === 'lr_coach' ? (
            //Change to the proper URL
            <Link
              to={RouteHelpers.getPath('organization-subscriptions')}
              className="text-black font-bold underline"
            >
              {i18n.ft(messages.view)}
            </Link>
          ) : (
            <span>--</span>
          )
      };
    }
  );

  const membersCount = data?.pagination.total_pages;

  function assignToCoach(coachId: number) {
    if (subscriptionId && selectedRows.length > 0) {
      const coachings = educatorsOnly.map(educatorId => ({
        coach_id: coachId,
        educator_id: educatorId
      }));
      createCoachings(parseInt(subscriptionId), coachings)
        .then(() => {
          setSelectedRows([]);
          setIsAssignCoachModalOpen(false);
          toastSuccess({
            message: i18n.ft(messages.success, { educators: roles.educators })
          });
        })
        .catch(() => {
          toastError({
            message: i18n.ft(messages.error)
          });
        });
    }
  }

  function renderView() {
    if (isLoading) {
      return <PageLoader />;
    }

    if (tableData && view === 'active') {
      return (
        <ActiveUsersView
          page={page}
          membersCount={membersCount}
          tableHeaders={tableHeaders}
          tableData={tableData}
          sortBy={sortBy || ''}
          sortDir={sortDir}
          handleSort={handleSort}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setIsAssignCoachModalOpen={setIsAssignCoachModalOpen}
          setPage={setPage}
          data={data}
          getLastPage={getLastPage}
        />
      );
    }

    if (view === 'coachGroups') {
      return <CoachGroups searchQuery={searchQuery} />;
    }

    return <UnsubscribedUsers searchQuery={searchQuery} />;
  }

  return (
    <div className="my-8 relative isolate">
      <BackgroundSVG className="absolute left-0 right-0 -mx-6 xl:-mx-14 z-0" />

      <AssignCoachModal
        isOpen={isAssignCoachModalOpen}
        onClose={() => {
          setIsAssignCoachModalOpen(false);
        }}
        onAssign={(coachId: number) => {
          assignToCoach(coachId);
        }}
        roles={roles}
      />

      <div className="relative py-6 pb-12 z-10">
        <div>
          <h2 className="text-white font-sans font-bold text-3xl pb-6">
            {i18n.ft(messages.learningResourcesUsers)}
          </h2>
        </div>
        <div className="p-6 bg-white rounded-2xl ">
          <div className="flex flex-col">
            <div className="mt-1 flex justify-between items-center">
              <UserStatusSelect
                value={view}
                onChange={value => setView(value)}
                options={[
                  { value: 'active', text: 'Active Users' },
                  { value: 'unsubcribed', text: 'Unsubscribed' },
                  { value: 'coachGroups', text: 'Coach Groups' }
                ]}
              />

              <div className="flex gap-6">
                <div className="relative">
                  <input
                    type="search"
                    className="border rounded-md max-w-94 min-w-80 h-10 p-2 placeholder:font-sans pr-7"
                    onChange={debounce(handleSearch, 300)}
                    placeholder={i18n.ft(messages.searchPlaceholder)}
                    aria-label={i18n.ft(messages.searchPlaceholder)}
                  />
                  <i className="fa fa-search absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
                </div>
                <PrimaryButton
                  icon="fa-solid fa-plus"
                  onClick={() =>
                    navigate(
                      RouteHelpers.getPath(
                        'organization-subscriptions-invite',
                        {
                          id: subscriptionId
                        }
                      )
                    )
                  }
                  disabled={!data}
                >
                  {i18n.ft(messages.invite)}
                </PrimaryButton>
              </div>
            </div>
          </div>
          {renderView()}
        </div>
      </div>
    </div>
  );
}

export default LearningResources;
