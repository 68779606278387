const PATH = 'fe.pages.organization.unsubscribed_users';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Unsubscribed Users'
  },
  nameAndEmail: {
    key: `${PATH}.name_and_email`,
    defaultValue: 'Name & Email'
  },
  searchPlaceholder: {
    key: `${PATH}.search_placeholder`,
    defaultValue: 'Search name or email'
  },
  role: {
    key: `${PATH}.role`,
    defaultValue: 'Role'
  },
  invitedOn: {
    key: `${PATH}.invited_on`,
    defaultValue: 'Invited On'
  },
  unsubscribedOn: {
    key: `${PATH}.unsubscribed_on`,
    defaultValue: 'Unsubscribed On'
  },
  usersCount: {
    key: `${PATH}.users_count`,
    defaultValue: 'Showing {{initial}}-{{end}} of {{total}}'
  },
  totalPages: {
    key: `${PATH}.total_pages`,
    defaultValue: 'Total: {{count}} pages'
  },
  filterRoles: {
    key: `${PATH}.filter_roles`,
    defaultValue: 'Filter roles'
  },
  clearRoles: {
    key: `${PATH}.clear_roles`,
    defaultValue: 'Clear selected roles'
  },
  deselectAll: {
    key: `${PATH}.deselect_all`,
    defaultValue: 'Deselect All'
  },
  resubscribe: {
    key: `${PATH}.resubscribe`,
    defaultValue: 'Resubscribe Users ({{count}})'
  }
};

export default messages;
