import i18n from 'helpers/i18n';
import messages from './messages';
import { Table } from 'components/Organization/Table';
import { Pagination } from 'components/Organization/Pagination';
import { PER_PAGE } from 'pages/Organization/LearningResources/utils';
import SelectUsersBanner from 'components/Organization/SelectUsersBanner';

interface UsersCountProps {
  page: number;
  membersCount: number;
  getLastPage: () => number;
}

function UsersCount({ page, membersCount, getLastPage }: UsersCountProps) {
  return (
    <div className="text-[#3C3F42] mt-6 mb-1.5 text-sm">
      {i18n.ft(messages.usersCount, {
        initial: page === 1 ? 1 : (page - 1) * PER_PAGE + 1,
        end: getLastPage(),
        total: membersCount
      })}
    </div>
  );
}

interface TableActionsProps {
  selectedRows: number[];
  setSelectedRows: React.Dispatch<React.SetStateAction<number[]>>;
  setIsAssignCoachModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function TableActions({
  selectedRows,
  setSelectedRows,
  setIsAssignCoachModalOpen
}: TableActionsProps) {
  return (
    <SelectUsersBanner
      setSelectedRows={setSelectedRows}
      assignCoachText={i18n.ft(messages.assignCoach)}
      resendInviteText={i18n.ft(messages.resendInvite, {
        count: selectedRows.length
      })}
      unsubscribeText={i18n.ft(messages.unsubscribe, {
        count: selectedRows.length
      })}
      deselectAllText={i18n.ft(messages.deselectAll)}
      assignCoachMethod={() => {
        setIsAssignCoachModalOpen(true);
      }}
      resendInviteMethod={() => {}}
      unsubscribeMethod={() => {}}
    />
  );
}

interface ActiveUsersViewProps {
  page: number;
  membersCount: number;
  tableHeaders: any[];
  tableData: any[];
  sortBy: string;
  sortDir: 'asc' | 'desc' | null | undefined;
  handleSort: (sortBy: string, sortDir: string) => void;
  selectedRows: number[];
  setSelectedRows: React.Dispatch<React.SetStateAction<number[]>>;
  setIsAssignCoachModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  data: { pagination: { total_pages: number } } | null;
  getLastPage: () => number;
}

function ActiveUsersView({
  page,
  membersCount,
  tableHeaders,
  tableData,
  sortBy,
  sortDir,
  handleSort,
  selectedRows,
  setSelectedRows,
  setIsAssignCoachModalOpen,
  setPage,
  data,
  getLastPage
}: ActiveUsersViewProps) {
  return (
    <>
      <UsersCount
        page={page}
        membersCount={membersCount}
        getLastPage={getLastPage}
      />
      <Table
        headers={tableHeaders}
        data={tableData}
        sortBy={sortBy}
        sortDir={sortDir}
        onSort={header => handleSort(header, sortDir ?? 'asc')}
        actions={
          selectedRows.length > 0 && (
            <TableActions
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              setIsAssignCoachModalOpen={setIsAssignCoachModalOpen}
            />
          )
        }
      />
      <div className="mt-6 flex justify-center">
        <Pagination
          page={page}
          onPageChange={setPage}
          total={data?.pagination.total_pages ?? 0}
          label={i18n.ft(messages.totalPages, {
            count: membersCount
          })}
        />
      </div>
    </>
  );
}

export default ActiveUsersView;
