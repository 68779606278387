import i18n from 'helpers/i18n';
import { useState } from 'react';
import messages from './messages';
import RouteHelpers from 'helpers/routes';
import { Checkbox } from '@headlessui/react';
import PageLoader from 'components/PageLoader';
import { Link, useParams } from 'react-router-dom';
import { useGetCoachGroups } from 'actions/coaches';
import { Table } from 'components/Organization/Table';
import { composeUser } from 'pages/Organization/Members/utils';
import { Pagination } from 'components/Organization/Pagination';
import { PER_PAGE } from 'pages/Organization/LearningResources/utils';

type Coaches = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  full_name: string;
  educators_count: number;
};

interface CoachGroupsProps {
  searchQuery: string;
}

function CoachGroups({ searchQuery }: CoachGroupsProps) {
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortDir, setSortDir] = useState<'asc' | 'desc' | null>(null);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const { id: subscriptionId } = useParams<{ id: string }>();

  const handleRowSelect = (userId: number) => {
    setSelectedRows(prevSelectedRows =>
      prevSelectedRows.includes(userId)
        ? prevSelectedRows.filter(id => id !== userId)
        : [...prevSelectedRows, userId]
    );
  };

  const handleHeaderSelect = () => {
    if (!data || !data.coaches) return;

    if (selectedRows.length <= 0) {
      const allUserIdsOnPage = data.coaches.map((coach: any) => coach.id);
      setSelectedRows(allUserIdsOnPage);
    } else {
      setSelectedRows([]);
    }
  };

  const sortMapping: { [key: string]: string } = {
    name: 'coach_name',
    educators: 'educators'
  };

  const baseParams = {
    query: searchQuery,
    sort_by: sortBy && sortBy in sortMapping ? sortMapping[sortBy] : sortBy,
    sort_dir: sortDir
  };

  const tableParams = {
    ...baseParams,
    per_page: PER_PAGE,
    page
  };

  const filteredTableParams = Object.fromEntries(
    Object.entries(tableParams).filter(([_, value]) => value != null)
  );

  const { data, isLoading } = useGetCoachGroups(
    Number(subscriptionId),
    filteredTableParams
  );

  const handleSort = (header: string) => {
    if (header === sortBy && sortDir === 'asc') {
      setSortDir('desc');
    } else if (header === sortBy && sortDir === 'desc') {
      setSortBy(null);
      setSortDir(null);
    } else {
      setSortBy(header);
      setSortDir('asc');
    }
  };

  const getLastPage = () => {
    if (page === 1) return PER_PAGE;

    const totalPages = data?.pagination.total_pages;
    if (page === totalPages) {
      return (data?.coaches.length || 0) + PER_PAGE * (page - 1);
    }

    return page * PER_PAGE;
  };

  const tableHeaders = [
    {
      content: (
        <Checkbox
          checked={selectedRows.length > 0}
          onChange={handleHeaderSelect}
          className="text-xl text-action"
        >
          {({ checked }) =>
            checked ? (
              <i className="fa-solid fa-square-minus" />
            ) : (
              <i className="fa-regular fa-square" />
            )
          }
        </Checkbox>
      ),
      accessor: 'checkbox'
    },
    {
      content: i18n.ft(messages.nameAndEmail),
      accessor: 'coach_name',
      sortable: true
    },
    {
      content: i18n.ft(messages.educators),
      accessor: 'educators',
      sortable: false
    }
  ];

  const tableData = data?.coaches.map((coach: Coaches) => {
    return {
      selected: selectedRows.includes(coach.id),
      checkbox: (
        <Checkbox
          checked={selectedRows.includes(coach.id)}
          onChange={() => handleRowSelect(coach.id)}
          className="text-xl text-action"
        >
          {({ checked }) =>
            checked ? (
              <i className="fa-solid fa-square-check" />
            ) : (
              <i className="fa-regular fa-square" />
            )
          }
        </Checkbox>
      ),
      coach_name: composeUser(coach.id, coach.full_name, coach.email),
      educators: (
        //Change to the proper URL
        <Link
          to={RouteHelpers.getPath('organization-subscriptions')}
          className="text-black font-bold underline"
        >
          {i18n.ft(messages.view, { count: coach.educators_count })}
        </Link>
      )
    };
  });

  const membersCount = data?.pagination.total_pages;

  return (
    <div className="relative isolate">
      <div className="relative z-10">
        <div className="bg-white rounded-2xl ">
          {isLoading ? (
            <PageLoader />
          ) : (
            <>
              {tableData && (
                <>
                  <div className="text-[#3C3F42] mt-6 mb-1.5 text-sm">
                    {i18n.ft(messages.usersCount, {
                      initial: page === 1 ? 1 : (page - 1) * PER_PAGE + 1,
                      end: getLastPage(),
                      total: membersCount
                    })}
                  </div>

                  <Table
                    headers={tableHeaders}
                    data={tableData}
                    sortBy={sortBy}
                    sortDir={sortDir}
                    onSort={handleSort}
                  />

                  <div className="mt-6 flex justify-center">
                    <Pagination
                      page={page}
                      onPageChange={setPage}
                      total={data?.pagination.total_pages ?? 0}
                      label={i18n.ft(messages.totalPages, {
                        count: membersCount
                      })}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default CoachGroups;
