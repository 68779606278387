import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import Download from 'images/download.svg';
import { useParams } from 'react-router-dom';
import React, { useState, useRef } from 'react';
import { uploadCsvImport } from 'actions/subscriptions';
import CloseIcon from 'images/learningResources/close_x_icon_black.svg';
import { toastError, toastSuccess } from 'components/Organization/Toast';

interface CSVMemberUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface ErrorResponse {
  title?: string;
  errors?: {
    file?: string[];
  };
}

function CSVMemberUploadModal({ isOpen, onClose }: CSVMemberUploadModalProps) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { run, isLoading, setError } = useAsync();
  const { id } = useParams();
  const subscriptionId = Number(id);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      setSelectedFile(event.dataTransfer.files[0]);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const resetInput = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      setSelectedFile(null);
    }
  };

  const formatErrorMessage = (
    errorResponse: ErrorResponse,
    defaultMessage: string
  ): string => {
    if (errorResponse && errorResponse.errors && errorResponse.errors.file) {
      const errorMessages = Object.values(errorResponse.errors.file)
        .flat()
        .slice(0, 2)
        .join('\n');
      return `${errorResponse.title ? errorResponse.title + ':\n' : ''}${errorMessages}`;
    }
    return defaultMessage;
  };

  const handleUpload = () => {
    if (selectedFile) {
      run(uploadCsvImport(subscriptionId, selectedFile), {
        onSuccess: () => {
          resetInput();
          onClose();
          toastSuccess({ message: i18n.ft(messages.success) });
        },
        onError: e => {
          resetInput();
          const errorMessage = formatErrorMessage(e, i18n.ft(messages.error));
          toastError({ message: errorMessage });
        }
      });
    } else {
      toastError({ message: i18n.ft(messages.noFile) });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg w-[1200px] max-w-full p-6 relative">
        <button
          className="absolute top-4 right-4 hover:text-gray-700 text-2xl font-bold"
          onClick={() => {
            setError(null);
            resetInput();
            onClose();
          }}
        >
          <img alt="" src={CloseIcon} />
        </button>

        <h2 className="text-2xl font-bold !mb-8">{i18n.ft(messages.title)}</h2>

        <div className="ml-4">
          <p className="text-lg font-bold !mb-4">
            {i18n.ft(messages.directions)}
          </p>

          <p className="text-gray-600 mb-6">{i18n.ft(messages.uploadText)}</p>

          <span className="border-b-2 border-blue-600">
            <a
              href="/"
              className="text-blue-600 no-underline mb-6 inline-block font-bold"
            >
              <img alt="" src={Download} className="inline align-middle pb-1" />{' '}
              <span>{i18n.ft(messages.template)}</span>
            </a>
          </span>
          <p className="text-gray-600 mb-6">{i18n.ft(messages.complete)}</p>

          <div
            className="border-2 border-dashed border-gray-400 rounded-lg p-12 text-center mb-6 font-bold"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <p className="text-gray-800 text-lg mb-2">
              {i18n.ft(messages.drop)}
            </p>
            <p className="text-gray-800 text-lg">
              {i18n.ft(messages.or)}{' '}
              <label className="text-blue-600 underline cursor-pointer">
                {i18n.ft(messages.browse)}
                <input
                  type="file"
                  className="hidden"
                  accept=".csv"
                  onChange={handleFileChange}
                  ref={inputRef}
                />
              </label>
            </p>
          </div>

          {selectedFile && (
            <p className="text-gray-600 mb-4">
              {i18n.ft(messages.file)}{' '}
              <span className="font-medium">{selectedFile.name}</span>
            </p>
          )}

          <div className="flex justify-center">
            <button
              type="button"
              className="bg-blue-600 text-white py-2 px-6 rounded-md shadow-md text-lg font-semibold cursor-pointer hover:bg-blue-500 disabled:bg-[#EBEFF4] disabled:cursor-not-allowed disabled:text-[#AFBFD2]"
              onClick={() => handleUpload()}
              disabled={!selectedFile || isLoading}
            >
              {isLoading ? (
                <i className="fa loader" />
              ) : (
                i18n.ft(messages.upload)
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CSVMemberUploadModal;
