import moment from 'moment-timezone';

/**
 * Utility function to get the user's browser timezone.
 * @returns The user's local timezone as a string (e.g., 'America/New_York', 'Asia/Tokyo').
 */

export const getUserTimeZone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

/**
 * Convert a UTC date to the user's local timezone and include the timezone abbreviation.
 * @param dateStr - The UTC date string (e.g., '2025-02-07').
 * @param format - The desired output format (default: 'YYYY-MM-DD HH:mm A').
 * @returns Formatted date with timezone abbreviation (e.g., "2025-02-07 12:00 PM PST")
 */
export const convertUTCToLocal = (
  dateStr: moment.MomentInput,
  format: string = 'YYYY-MM-DD hh:mm A'
): string => {
  const userTimeZone = getUserTimeZone();

  if (!dateStr) return '';

  const localTime = moment.utc(dateStr).tz(userTimeZone);

  const timeZoneAbbr = localTime.format('z');

  return `${localTime.format(format)} ${timeZoneAbbr}`;
};
