import axios from 'axios';
import { Account } from 'types/api/Account';
import { CsvImport } from 'types/api/CsvImport';
import { Subscription } from 'types/api/Subscription';
import { AccountMember } from 'types/api/AccountMember';

import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
  useMutation
} from '@tanstack/react-query';

export function getAccounts(params = {}) {
  return axios.get('/api/v2/accounts', { params });
}

export function createAccount(account = {}) {
  return axios.post('/api/v2/accounts', { account });
}

export function getAccount(id: number, params = {}) {
  return axios.get<Account>(`/api/v2/accounts/${id}`, { params });
}

export function useGetAccount(accountId: number, params = {}) {
  return useQuery({
    queryKey: ['accounts', accountId, params],
    queryFn: () =>
      getAccount(accountId, params).then(response => response.data),
    enabled: Boolean(accountId)
  });
}

export function updateAccount(id: number, account = {}) {
  return axios.put(`/api/v2/accounts/${id}`, { account });
}

export function uploadAccountHierarchy(accountId: number, file: File) {
  const data = new FormData();
  data.append('csv_file', file);

  return axios.post(`/api/v2/accounts/${accountId}/csv_import`, data);
}

export function uploadHierarchyByNode(
  accountId: number,
  file: File,
  nodeId: number
) {
  const data = new FormData();
  data.append('csv_file', file);
  data.append('node_id', nodeId.toString());

  return axios.put(`/api/v2/accounts/${accountId}/csv_append`, data);
}

export function getCSVImports(accountId: number) {
  return axios.get<CsvImport[]>(`/api/v2/accounts/${accountId}/csv_imports`);
}

export function archiveAccount(id: number) {
  return axios.delete(`/api/v2/nodes/${id}`);
}

type GetAccountSubscriptionsRes = {
  subscriptions: Subscription[];
};

export function getAccountSubscriptions(id: number, params = {}) {
  return axios.get<GetAccountSubscriptionsRes>(
    `/api/v2/accounts/${id}/subscriptions`,
    { params }
  );
}

export function useGetAccountSubscriptions(id: number, params = {}) {
  return useQuery({
    queryKey: ['accounts', id, 'subscriptions', params],
    queryFn: () =>
      getAccountSubscriptions(id, params).then(response => response.data)
  });
}

export function getAccountSubscription(accountId: number, id: number) {
  return axios.get(`/api/v2/accounts/${accountId}/subscriptions/${id}`);
}

export function getAccountTrainings(id: number, params = {}) {
  return axios.get(`/api/v2/accounts/${id}/trainings`, { params: params });
}

export function getAccountTrainingParticipants(id: number, trainingId: number) {
  return axios.get(
    `/api/v2/accounts/${id}/trainings/${trainingId}/participants`
  );
}

export function getAccountUsers(id: number, params = {}) {
  return axios.get(`/api/v2/accounts/${id}/users`, { params: params });
}

export function createAccountUser(accountId: number, accountUser = {}) {
  return axios.post(`/api/v2/accounts/${accountId}/users`, {
    account_user: accountUser
  });
}

export function updateAccountUser(
  accountId: number,
  userId: number,
  accountUser = {}
) {
  return axios.put(`/api/v2/accounts/${accountId}/users/${userId}`, {
    account_user: accountUser
  });
}

export function getAccountUser(accountId: number, userId: number) {
  return axios.get(`/api/v2/accounts/${accountId}/users/${userId}`);
}

export function archiveAccountUser(accountId: number, userId: number) {
  return axios.delete(`/api/v2/accounts/${accountId}/users/${userId}`);
}

export function getAccountCustomAttributes(accountId: number) {
  return axios.get(
    `/api/v3/admin/accounts/${accountId}/member_custom_attributes`
  );
}

// Only used to generate the link for downloading the CSV file.
export function downloadAccountUserFile(accountId: number) {
  return `/api/v2/accounts/${accountId}/export_account_members`;
}

export function uploadAccountMembers(accountId: number, file: File) {
  const data = new FormData();
  data.append('file', file);

  return axios.post(`/api/v2/accounts/${accountId}/members_upload`, data);
}

export function getAccountUserTokens(id: number, params = {}) {
  return axios.get(`/api/v2/accounts/${id}/user_tokens`, { params });
}

export function getSalesforceAccount(salesforceId: string) {
  return axios.get(`/api/v2/accounts/salesforce/${salesforceId}`);
}

export function createAccountProductKey(accountId: number, userToken = {}) {
  return axios.post(`/api/v2/accounts/${accountId}/user_tokens`, {
    user_token: userToken
  });
}

export function updateAccountProductKey(
  accountId: number,
  userTokenId: number,
  userToken = {}
) {
  return axios.put(`/api/v2/accounts/${accountId}/user_tokens/${userTokenId}`, {
    user_token: userToken
  });
}

export function searchAccountsAutocomplete(query: string) {
  return axios.get('/api/v2/accounts/search_autocomplete', {
    params: { query }
  });
}

// fetch the entire hierarchy
export function getHierarchy(id: number) {
  return axios.get(`/api/v2/accounts/${id}/hierarchy`);
}

// fetch hierarchy scoped to the user's view
export function getHierarchyScoped(id: number) {
  return axios.get(`/api/v2/accounts/${id}/hierarchy/scoped`);
}

export function getAccountEducators(accountId: number, params = {}) {
  return axios.get(`/api/v2/accounts/${accountId}/educators`, { params });
}

/** Organization account */

export function getOrganizationAccount(id: number) {
  return axios.get(`/api/v2/ca/accounts/${id}`);
}

export function getOrganizationAccountSummary() {
  return axios.get(`/api/v2/ca/accounts/summary`);
}

export function updateOrganizationAccountSettings(params = {}) {
  return axios.put(`/api/v2/ca/accounts/settings`, params);
}

type AccoutMember = {
  id: number;
  name: string;
  email: string;
  role: string;
  last_login: string;
  hierarchy: string[][];
};

type GetAccountMembersResponse = {
  hierarchy_present: boolean;
  users: AccoutMember[];
  pagination: {
    page: number;
    total_pages: number;
    total_count: number;
  };
};

type AddUserToAccountParams = {
  accountId: number;
  params: {
    users: {
      email: string;
      first_name: string;
      last_name: string;
      role_id: number;
    }[];
  };
};

type ArchiveUserParams = {
  accountId: number;
  params: {
    user_ids: number[];
  };
};

export function addUserToAccount({
  accountId,
  params
}: AddUserToAccountParams) {
  return axios.post(`/api/v2/ca/accounts/${accountId}/users`, params);
}

export function updateAccountMember(
  accountId: number,
  userId: number,
  params = {}
) {
  return axios.put(`/api/v2/ca/accounts/${accountId}/users/${userId}`, params);
}

export function getAccoutMembers(accountId: number, params = {}) {
  return axios.get<GetAccountMembersResponse>(
    `/api/v2/ca/accounts/${accountId}/members`,
    { params }
  );
}

export function useGetAccountMembers(accountId: number, params = {}) {
  return useQuery({
    queryKey: ['ca', 'accounts', accountId, 'members', params],
    queryFn: () =>
      getAccoutMembers(accountId, params).then(response => response.data)
  });
}

export function getAccountMember(accountId: number, userId: number) {
  return axios.get<AccountMember>(
    `/api/v2/ca/accounts/${accountId}/users/${userId}`
  );
}

export function useGetAccountMember(accountId: number, userId: number) {
  return useQuery({
    queryKey: ['ca', 'accounts', accountId, 'users', userId],
    queryFn: () =>
      getAccountMember(accountId, userId).then(response => response.data)
  });
}

type MemberLog = {
  id: number;
  action: string;
  created_at: string;
  performed_by: {
    user_id: number;
    user_name: string;
  };
  details?: {
    old_value: string | string[];
    new_value: string | string[];
  };
};

type GetAccountMemberLogRes = {
  account_user_logs: MemberLog[];
};

export function getAccountMemberLog(accountId: number, userId: number) {
  return axios.get<GetAccountMemberLogRes>(
    `/api/v2/ca/accounts/${accountId}/users/${userId}/log`
  );
}

export function useGetAccountMemberLog(accountId: number, userId: number) {
  return useQuery({
    queryKey: ['ca', 'accounts', accountId, 'users', userId, 'log'],
    queryFn: () =>
      getAccountMemberLog(accountId, userId).then(response => response.data)
  });
}

export function archiveUsersToAccount({
  accountId,
  params
}: ArchiveUserParams) {
  return axios.post(
    `/api/v2/ca/accounts/${accountId}/users/archive_multiple`,
    params
  );
}

type ReactivateUsersParams = {
  accountId: number;
  params: {
    user_ids: number[];
  };
};

export function reactivateUsersToAccount({
  accountId,
  params
}: ReactivateUsersParams) {
  return axios.post(
    `/api/v2/ca/accounts/${accountId}/users/unarchive_multiple`,
    params
  );
}

type GetAccountNodeParams = {
  accountId: number;
  nodeId: number;
};

// Account hierarchy nodes, scoped to the user's view.
export function getAccountNodes(accountId: number) {
  return axios.get(`/api/v2/ca/accounts/${accountId}/nodes`);
}

export function getAccountNode({ accountId, nodeId }: GetAccountNodeParams) {
  return axios.get(`/api/v2/ca/accounts/${accountId}/nodes/${nodeId}`);
}

export function useGetAccountNode({ accountId, nodeId }: GetAccountNodeParams) {
  return useQuery({
    queryKey: ['ca', 'accounts', accountId, 'nodes', nodeId],
    queryFn: () =>
      getAccountNode({ accountId, nodeId }).then(response => response.data)
  });
}

export function createAccountNode(accountId: number, params = {}) {
  return axios.post(`/api/v2/ca/accounts/${accountId}/nodes`, params);
}

export function deleteAccountNode(accountId: number, nodeId: number) {
  return axios.delete(`/api/v2/ca/accounts/${accountId}/nodes/${nodeId}`);
}

export function updateAccountNode(
  accountId: number,
  nodeId: number,
  params = {}
) {
  return axios.put(`/api/v2/ca/accounts/${accountId}/nodes/${nodeId}`, params);
}

export function updateAccountNodeTags(
  accountId: number,
  nodeId: number,
  params = {}
) {
  return axios.put(
    `/api/v2/ca/accounts/${accountId}/nodes/${nodeId}/tag`,
    params
  );
}

type TAG_GROUPS =
  | 'funding_stream'
  | 'special_population'
  | 'program_descriptors'
  | 'program_descriptors_custom';

export function getTagsByGroup(group: TAG_GROUPS) {
  return axios.get(`/api/v2/ca/tag_groups/${group}`);
}

export function useGetTagsByGroup(group: TAG_GROUPS) {
  return useQuery({
    queryKey: ['ca', 'tag_groups', group],
    queryFn: () => getTagsByGroup(group).then(response => response.data)
  });
}

/** Organization roles. */
export function getAccoutRoles(accountId: number) {
  return axios.get(`/api/v2/ca/accounts/${accountId}/active_roles`);
}

export function useGetAccountRoles(accountId: number) {
  return useQuery({
    queryKey: ['ca', 'accounts', accountId, 'active_roles'],
    queryFn: () => getAccoutRoles(accountId).then(response => response.data)
  });
}

/** React Query Actions */

export function useGetAccountById(
  accountId: number,
  opt?: Partial<UseQueryOptions<Account>>
) {
  return useQuery({
    queryKey: ['accounts', accountId],
    queryFn: () => getAccount(accountId).then(response => response.data),
    ...opt
  });
}

export function useUpdateAccount() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, values }: { id: number; values: any }) =>
      updateAccount(id, values),
    onSuccess: (response, variables) =>
      queryClient.setQueryData(['accounts', variables.id], response.data)
  });
}

export function useCreateAccount() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createAccount,
    onSuccess: ({ data }) =>
      queryClient.setQueryData(['accounts', data.id], data)
  });
}

export function useGetHierarchy(accountId: number) {
  return useQuery({
    queryKey: ['accounts', accountId, 'hierarchy'],
    queryFn: () => getAccountNodes(accountId).then(response => response.data),
    enabled: Boolean(accountId)
  });
}

export function useOrganizationAccount(accountId: number) {
  return useQuery({
    queryKey: ['ca', 'accounts', accountId],
    queryFn: () =>
      getOrganizationAccount(accountId).then(response => response.data),
    enabled: Boolean(accountId)
  });
}
