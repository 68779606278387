import moment from 'moment-timezone';
import { convertUTCToLocal } from 'helpers/date/local_timezone';
import * as R from 'ramda';
import i18n from 'helpers/i18n';
import settings from 'helpers/settings';
import messages from './messages';
import React, { useState } from 'react';
import StatusMessage from './StatusMessage';
import { AgeLevel } from 'types/api/AgeLevel';
import DownloadButton from './DownloadButton';
import ShowRenewButton from './ShowRenewButton';
import { AgeLevelGroupTag, AgeLevelTag } from 'components/AgeLevel';
import { formatDate } from 'helpers/date/formatDate';
import { Icon, Table } from 'semantic-ui-react';
import { Tag } from 'types/api/Tag';

import {
  ActionsContainer,
  Container,
  DetailsContainer,
  DetailsMain,
  DownloadContainer,
  DownloadContainerMobile,
  DownloadLabel,
  ExpirationContainer,
  ExpirationDate,
  ExpirationLabel,
  HistoryContainer,
  Name,
  RenewContainer,
  StatusContainer,
  StatusContainerMobile,
  TableBody,
  TableHeaderCell,
  TagContainer,
  TextButton,
  AuthorizedAgeLevelsContainer
} from './Styled';
import { AGE_LEVEL_GROUP_ABBREVIATIONS } from 'types/api/AgeLevelGroup';
import useFeatureFlags from 'hooks/useFeatureFlags';
import AuthorizedAgeLevels from './AuthorizedAgeLevels';

export type CertificationCardBase = {
  /** Uniquely identifies a certification type. */
  certificationKey: string;

  /** Certification data origin (onelogin or core). */
  dataSource: 'onelogin' | 'core';

  /** Certification instance ID in origin. */
  dataSourceID: number;

  /** Certification name. */
  name: string;

  /** Certification start date (date certified). */
  startDate: string;

  /** Certification end date (date expired). */
  endDate: string;

  /** Array of age levels. */
  ageLevels: AgeLevel[];

  /** Certification group name. */
  groupName: string;

  /** Indicates if a certification instance has expired. */
  expired: boolean;

  /** Indicates if a certification instance can be renewed. */
  renewable: boolean;

  /** First date when certification instance can be renewed. */
  renewableOn?: string;

  /** Last date when certification instance can be renewed. */
  renewableUntil?: string;

  /** Number of days left to renew a certification instance. */
  renewableDays?: number;

  /** Certifications can have a test score associated. */
  testScore?: number;

  /** Renewal URL. */
  renewalURL?: string;

  /** Boolean whether the expanded option should be open since render. */
  selected?: boolean;

  /** Course enrollment data if user is in the process of renewing. */
  enrollment?: { url: string; message: string };

  /** Preparation Template Id if the user wants to prepare for the recertification
   * without a purchase */
  testPreparationTemplateId?: number;

  /** Attempt Id of the test */
  currentAttemptId?: number | null;

  /** Attempt number it could be up to 5 */
  currentAttemptNumber?: number | null;

  /** The ID of the certification that replaces this one */
  transitionId?: number | null;

  /** Indicates a renewed Certification which its children is 2008 */
  transitionedTo2ndEdition?: boolean;

  /** Describes to where this certification was transitioned to  */
  transitionText: string | null;

  /** When a certification was transitioned */
  parentId?: number | null;

  /** Age Level Group Code */
  ageLevelGroupCode?: AGE_LEVEL_GROUP_ABBREVIATIONS;

  /** if `alt name` = null then it contains Age Level Group Name */
  displayName?: string;

  /** Observable CLASS 2008 age levels */
  authorized2008AgeLevels?: AgeLevel[];

  /** Tags */
  tags?: Tag[];
};

export type CertificationCardProps = CertificationCardBase & {
  /** Array of certifications sorted by end date (desc). */
  history: CertificationCardBase[];
};

const CertificationCard: React.FC<CertificationCardProps> = ({
  name,
  endDate,
  startDate,
  ageLevels,
  expired,
  renewable,
  renewableOn,
  renewableUntil,
  renewableDays,
  renewalURL,
  history,
  dataSource,
  dataSourceID,
  selected,
  testPreparationTemplateId,
  currentAttemptId,
  currentAttemptNumber,
  transitionText,
  transitionedTo2ndEdition,
  ageLevelGroupCode,
  authorized2008AgeLevels,
  displayName,
  tags
}) => {
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const [expanded, setExpanded] = useState(selected || false);

  const mEndDate = moment(endDate);
  const renewableOnDate = moment(renewableOn);
  const certificateAt = moment(startDate);
  const limitDate = settings.lookup('REACT_APP_K3_OBS_CERT_ISSUE_LIMIT_DATE');
  const groupName = history[0].groupName;

  const expirationTime = () => {
    if (!mEndDate.isValid()) {
      return;
    }

    return convertUTCToLocal(endDate);
  };

  const renewableTime = () => {
    if (!renewableOnDate.isValid()) {
      return;
    }

    return convertUTCToLocal(renewableOn);
  };

  const ageLevelName = (ageLevel: string) => {
    if (dataSource === 'onelogin') {
      return ageLevel;
    }
    if (
      ageLevel === 'K-3' &&
      groupName === 'observer' &&
      !certificateAt.isBefore(limitDate)
    ) {
      return `Pre-K${'\u2013'}3rd`;
    } else {
      return ageLevel;
    }
  };

  const transitionedFromCgcTag = tags?.find(
    tag => tag.name === 'transitioned_from_cgc'
  );

  return (
    <Container>
      <DetailsContainer>
        <DetailsMain>
          <Name>{name}</Name>

          <TagContainer>
            {ageLevelGroupCode === 'PKK3' ? (
              <AgeLevelGroupTag
                abbreviation={ageLevelGroupCode}
                className="border-2"
                backgroundColor={'#385e8e'}
              >
                {displayName}
              </AgeLevelGroupTag>
            ) : (
              ageLevels.map(ageLevel => (
                <AgeLevelTag
                  key={ageLevel.id}
                  abbreviation={ageLevel.abbreviation}
                >
                  {ageLevelName(ageLevel.name)}
                </AgeLevelTag>
              ))
            )}
          </TagContainer>

          {transitionedFromCgcTag && (
            <div className="mt-3">
              <p>{transitionedFromCgcTag.description}</p>
            </div>
          )}

          <StatusContainerMobile>
            <StatusMessage
              expired={expired}
              endDate={endDate}
              renewable={renewable}
              renewableOn={renewableOn}
              renewableUntil={renewableUntil}
              renewableDays={renewableDays}
              timezone={expirationTime()}
              renewableTime={renewableTime()}
              testPreparationTemplateId={testPreparationTemplateId}
              currentAttemptId={currentAttemptId}
              currentAttemptNumber={currentAttemptNumber}
              transitionedTo2ndEdition={transitionedTo2ndEdition}
              transitionText={transitionText}
            />
          </StatusContainerMobile>
        </DetailsMain>

        <ExpirationContainer>
          <ExpirationLabel>{i18n.ft(messages.expires)}</ExpirationLabel>
          <ExpirationDate>{expirationTime()}</ExpirationDate>
        </ExpirationContainer>

        <DownloadContainer>
          <DownloadLabel>{i18n.ft(messages.certificate)}</DownloadLabel>
          <DownloadButton
            dataSource={dataSource}
            dataSourceID={dataSourceID}
            startDate={startDate}
            endDate={endDate}
            title={name}
          >
            <Icon name="download" />
            {i18n.ft(messages.download)}
          </DownloadButton>
        </DownloadContainer>

        <RenewContainer>
          <ShowRenewButton
            renewalURL={renewalURL}
            buttonText={i18n.ft(messages.renew)}
            renewable={renewable}
            transitionedTo2ndEdition={transitionedTo2ndEdition}
          />
        </RenewContainer>
      </DetailsContainer>

      {isFeatureFlagEnabled('authorized_2008_age_levels') ? (
        <AuthorizedAgeLevelsContainer>
          <AuthorizedAgeLevels ageLevels={authorized2008AgeLevels} />
        </AuthorizedAgeLevelsContainer>
      ) : (
        <></>
      )}

      <ActionsContainer>
        <StatusContainer>
          <StatusMessage
            expired={expired}
            endDate={endDate}
            renewable={renewable}
            renewableOn={renewableOn}
            renewableUntil={renewableUntil}
            renewableDays={renewableDays}
            renewableTime={renewableTime()}
            testPreparationTemplateId={testPreparationTemplateId}
            currentAttemptId={currentAttemptId}
            currentAttemptNumber={currentAttemptNumber}
            transitionedTo2ndEdition={transitionedTo2ndEdition}
            transitionText={transitionText}
          />
        </StatusContainer>

        <DownloadContainerMobile>
          <DownloadButton
            dataSource={dataSource}
            dataSourceID={dataSourceID}
            startDate={startDate}
            endDate={endDate}
            title={name}
          >
            <Icon name="download" />
            {i18n.ft(messages.certificate)}
          </DownloadButton>
        </DownloadContainerMobile>

        <TextButton onClick={() => setExpanded(!expanded)}>
          {expanded ? (
            i18n.ft(messages.hideHistory)
          ) : (
            <>
              <Icon name="history" />
              {i18n.ft(messages.showHistory)}
            </>
          )}
        </TextButton>
      </ActionsContainer>

      {expanded && (
        <HistoryContainer>
          <Table unstackable>
            <Table.Header>
              <Table.Row>
                <TableHeaderCell>
                  {i18n.ft(messages.dateCertified)}
                </TableHeaderCell>
                <TableHeaderCell>{i18n.ft(messages.expires)}</TableHeaderCell>

                {dataSource === 'core' && (
                  <TableHeaderCell>
                    {i18n.ft(messages.testScore)}
                  </TableHeaderCell>
                )}
              </Table.Row>
            </Table.Header>

            <TableBody>
              {history.map(certRow => {
                const certStartDate = moment(certRow.startDate).format('L');
                const certEndDate = moment(certRow.endDate);

                return (
                  <Table.Row
                    key={`${certRow.dataSource}-${certRow.dataSourceID}`}
                  >
                    <Table.Cell>{certStartDate}</Table.Cell>
                    <Table.Cell>
                      {certEndDate.isValid()
                        ? certEndDate.format('L')
                        : i18n.ft(messages.notApplies)}
                    </Table.Cell>

                    {certRow.dataSource === 'core' && (
                      <Table.Cell>
                        {R.isNil(certRow.testScore)
                          ? '-'
                          : `${certRow.testScore}%`}
                      </Table.Cell>
                    )}
                  </Table.Row>
                );
              })}
            </TableBody>
          </Table>
        </HistoryContainer>
      )}
    </Container>
  );
};

export default CertificationCard;
